export const statesVisited = new Map([
    ["Vermont", 0],
    ["North-Dakota", 0],
    ["Iowa", 2],
    ["New-Hampshire", 0],
    ["Hawaii", 0],
    ["Utah", 2],
    ["Colorado", 2],
    ["Idaho", 2],
    ["Massachusetts", 2],
    ["South-Dakota", 2],
    ["Virginia", 1],
    ["Maine", 0],
    ["Wisconsin", 2],
    ["Nebraska", 2],
    ["Oklahoma", 2],
    ["Alabama", 2],
    ["Delaware", 0],
    ["Florida", 0],
    ["Kansas", 0],
    ["Missouri", 2],
    ["New-Jersey", 2],
    ["Arkansas", 2],
    ["Indiana", 2],
    ["Minnesota", 2],
    ["Montana", 0],
    ["South-Carolina", 2],
    ["Texas", 2],
    ["Rhode-Island", 0],
    ["Tennessee", 2],
    ["Connecticut", 2],
    ["Georgia", 1],
    ["Wyoming", 2],
    ["Maryland", 2],
    ["Pennsylvania", 2],
    ["New-York", 2],
    ["Ohio", 2],
    ["Oregon", 2],
    ["California", 2],
    ["Nevada", 2],
    ["Illinois", 2],
    ["North-Carolina", 2],
    ["Kentucky", 2],
    ["Louisiana", 0],
    ["Michigan", 0],
    ["Washington", 0],
    ["West-Virginia", 2],
    ["Arizona", 1],
    ["New-Mexico", 2],
    ["Mississippi", 2],
    ["District-of-Columbia", 2],
    ["Alaska", 0]
])